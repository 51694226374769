import { configureStore } from "@reduxjs/toolkit";

import languageReducer from "./language-slice";
import presetReducer from "./preset-slice";
import selectedFilterReducer from "./selected-filter-slice";
import selectedLanguageReducer from "./selected-language-slice";
import sentimentFilterReducer from "./sentiment-filter-slice";
import snackbarReducer from "./snackbar-slice";
import surveyDetailsReducer from "./survey-details-slice";
import surveyReducer from "./survey-slice";
import userFilterPresetsReducer from "./user-filter-preset";

export const makeStore = () => {
    return configureStore({
        reducer: {
            surveys: surveyReducer,
            surveyDetails: surveyDetailsReducer,
            userFilterPresets: userFilterPresetsReducer,
            selectedFilters: selectedFilterReducer,
            preset: presetReducer,
            snackbar: snackbarReducer,
            languages: languageReducer,
            selectedLanguage: selectedLanguageReducer,
            sentimentFilter: sentimentFilterReducer,
        },
    });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
