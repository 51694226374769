import React from "react";
import { useNavigate } from "react-router-dom";

import CheckIcon from "../../assets/check.svg";
import CloseIcon from "../../assets/close_icon.svg";

import styles from "./styles.module.css";

interface SnackbarProps {
    open: boolean;
    onClose: () => void;
    title: string;
    message: string | React.ReactNode;
}

const ToastNotification: React.FC<SnackbarProps> = ({ open, onClose, message, title }) => {
    const navigate = useNavigate();
    const navigateToSummary = () => {
        navigate(`../text-summarisation`);
        onClose();
    };
    return (
        <div className={`${styles.snackbar} ${open ? styles.show : ""}`}>
            <div className={styles.container}>
                <div>
                    <img src={CheckIcon} alt="" />
                </div>
                <div className={styles.titleTextContainer}>
                    <div className={styles.content}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.text}>{message}</div>
                    </div>
                    <div>
                        <button className={styles.summaryBtn} onClick={navigateToSummary}>
                            View Summary
                        </button>
                    </div>
                </div>
                <div>
                    <button onClick={onClose} className={styles.close}>
                        <img src={CloseIcon} alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ToastNotification;
