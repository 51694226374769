import { Pagination, Snackbar, Alert } from "@mui/material";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import ActiveFilter from "../../assets/active_filter.svg";
import ExportButton from "../../assets/export_button.svg";
import FilterIcon from "../../assets/filter.svg";

import { SENTIMENT_FILTER_OPTIONS, COMMENT_PAGE_LIMIT, NO_DATA_MSG, ERROR_MSG, SUMMARY_READY, COMMENTS_LEGEND_TEXT, TRANSLATION_TEXT_COMMENTS, EXPORT_ERROR } from "../../constants/constants";
import { extendedApiWrapper, QuestionListResponse, CommentStructure } from "../../services/api-handlers";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { hideSnackbar } from "../../store/snackbar-slice";
import { convertObjectToOptions } from "../../utils/utility-function";
import CustomizedDialog from "../dialog/timeout-dialog";
import TranslationDialog from "../dialog/translation-dialog";
import Filter from "../filter/filter";
import SentimentLegend from "../legends/legends";
import Loader from "../loader/auth-loader";
import MessageCard from "../message-card/message-card";
import SelectDropdown from "../select-dropdown/select-dropdown";
import SentimentFilter from "../sentiment-filter/sentiment-filter";
import ToastNotification from "../snackbar/snackbar";
import QuestionCard from "../text-summarisation/question-card";
import { LanguageOption } from "../text-summarisation/text-summarisation";

import CommentCard from "./comment-card";

import styles from "./styles.module.css";

const Comments = () => {
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionListResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [activeTags, setActiveTags] = useState<string[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
    const [questions, setQuestions] = useState<QuestionListResponse[]>([]);
    const [comments, setComments] = useState<CommentStructure[]>([]);
    const [commentsCount, setCommentsCount] = useState<number | null>(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [unauthorized, setUnauthorized] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption[]>([{ value: "en", label: "English" }]);
    const [transLationStatus, setTransLationStatus] = useState<string>("");
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmedLanguage, setConfirmedLanguage] = useState<LanguageOption[]>([{ value: "en", label: "English" }]);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [exportError, setExportError] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const displaySnackbar = useAppSelector((state) => state.snackbar.open);
    const filters = useAppSelector((state) => state.selectedFilters.selectedFilters);
    const languageList = useAppSelector((state) => state.languages.data);
    const surveyName = useAppSelector((state) => state.surveyDetails.data?.name);

    const handleSelectChange = (values: QuestionListResponse[]) => {
        setSelectedQuestion(values.length > 0 ? values[0] : null);
    };

    const fetchQuestions = async () => {
        if (!selectedSurveyId) return;
        try {
            const response = await extendedApiWrapper.getQuestionsList(selectedSurveyId);
            if (response.data && Array.isArray(response.data)) {
                setQuestions(response.data);
                setSelectedQuestion(response.data[0]);
            }
        } catch (error) {
            console.error("Error fetching questions", error);
        }
    };

    const fetchComments = async (appliedFilters: Record<string, string[]> | null = null, isPollingRequest = false) => {
        if (!selectedSurveyId || !selectedQuestion) return;
        if (!isPollingRequest) {
            setLoading(true);
        }
        setError(false);
        try {
            const requestData = {
                page_limit: COMMENT_PAGE_LIMIT,
                page_offset: currentPage - 1,
                question_id: selectedQuestion.id,
                sentiment: activeTags,
                filters: appliedFilters ?? filters,
                language_code: String(confirmedLanguage[0]?.value) || "en",
            };

            const response = await extendedApiWrapper.getSurveyComments(selectedSurveyId, requestData);
            if (response.data && Array.isArray(response.data.comments)) {
                setComments(response.data.comments);
                setCommentsCount(response.data.total_count);
                setTransLationStatus(response.data.translation_status);
                setFiltersApplied(!!appliedFilters || Object.keys(filters).length > 0);
                setError(false);
            } else if (response.status === 401) {
                setUnauthorized(true);
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
        } finally {
            if (!isPollingRequest) {
                setLoading(false); // Stop loader only for non-polling calls
            }
        }
    };
    const handleFilterOpen = () => {
        setIsOpen(true);
    };
    const handleFilterClose = () => {
        setIsOpen(false);
    };
    const handleClose = () => {
        setUnauthorized(false);
        navigate(0);
    };

    useEffect(() => {
        fetchQuestions();
    }, [selectedSurveyId]);

    useEffect(() => {
        if ((activeTags.length > 0 && currentPage > 1) || (selectedQuestion && currentPage > 1) || (Object.keys(filters).length > 0 && currentPage > 1)) {
            setCurrentPage(1);
        } else {
            if (Object.keys(filters).length > 0) {
                fetchComments(filters);
                handleFilterClose();
            } else {
                fetchComments();
            }
        }
    }, [activeTags, selectedQuestion, filters]);

    useEffect(() => {
        // Stop any ongoing polling before starting a new one
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;
        }

        // Start polling only when translation status is "processing"
        if (transLationStatus === "processing") {
            pollingIntervalRef.current = setInterval(() => {
                fetchComments(null, true);
            }, 10000);
        }

        // Cleanup the polling interval when translation status is completed or failed
        return () => {
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
                pollingIntervalRef.current = null;
            }
        };
    }, [transLationStatus, confirmedLanguage, activeTags, currentPage]);

    useEffect(() => {
        fetchComments();
    }, [selectedSurveyId, currentPage, confirmedLanguage]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleChange = (selected: LanguageOption[]) => {
        if (selected.length === 0) {
            return;
        }
        const selectedLang = selected[0];

        // If the selected language is the same as the confirmed language, do nothing
        if (selectedLang.value === confirmedLanguage[0]?.value) {
            return;
        }

        // If the selected language is English, update confirmed language directly and don't open the modal
        if (selectedLang.value === "en") {
            setConfirmedLanguage(selected);
            return; // Don't open the modal if the selected language is English
        }

        // For non-English languages, open the modal and set the selected language temporarily
        setSelectedLanguage(selected);
        setModalOpen(true);
    };

    const languageOptions = useMemo(() => {
        return convertObjectToOptions(languageList || {});
    }, [languageList]);

    const handleSelectionChange = (selectedValues: string[]) => {
        setActiveTags(selectedValues);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };
    const handleCancel = () => {
        // Revert language to previously confirmed language
        setSelectedLanguage(confirmedLanguage);

        // Close the modal
        setModalOpen(false);
    };
    const handleTranslateClose = () => {
        handleCancel();
    };
    const handleTranslate = () => {
        setConfirmedLanguage(selectedLanguage);
        handleModalClose();
    };

    const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

    const retryFetch = async (url: string, retries: number, delayTime: number) => {
        let lastError = null;
        for (let attempt = 1; attempt <= retries; attempt++) {
            try {
                const blob_response = await fetch(url);
                if (!blob_response.ok) {
                    throw new Error(`Failed to fetch: ${blob_response.statusText}`);
                }
                return blob_response;
            } catch (error) {
                lastError = error;
                if (attempt < retries) {
                    console.log(`Attempt ${attempt} failed, retrying in ${delayTime / 1000} seconds...`);
                    await delay(delayTime); // Wait before retrying
                }
            }
        }
        // If all retries fail, throw the last encountered error
        throw lastError;
    };

    const handleExportClick = async () => {
        if (!selectedSurveyId) {
            return;
        }

        const requestBody = {
            filters: Object.keys(filters).length > 0 ? filters : {},
        };
        setIsExporting(true);
        setExportError(false);

        try {
            const response = await extendedApiWrapper.exportData(selectedSurveyId, requestBody);

            const s3_presigned_url = response.data?.url;

            //dynamic timeout before calling S3 Presigned URL.
            const time_delay = response.data?.wait_time || 30000;
            if (!s3_presigned_url) {
                throw new Error("empty presigned ur;");
            }
            await delay(time_delay);
            // retry presigned url with 5 retries after 30 seconds.
            const blob_response = await retryFetch(s3_presigned_url, 5, 30000);
            const fileBlob = await blob_response.blob();
            const filename = `survey_comment_export_${surveyName}.xlsx`;

            // Create a temporary <a> element to trigger the download
            const link = document.createElement("a");
            const url = URL.createObjectURL(fileBlob);

            // Set the attributes for the download link
            link.href = url;
            link.download = filename;

            // Append the link to the document, trigger the download, and remove the link
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            setExportError(true);
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <div className={styles.commentsContainer}>
            <div className={styles.titleContainer}>
                <div className={styles.content}>
                    Comments <span>({commentsCount} Comments)</span>{" "}
                </div>
                <div className={styles.buttonContainer}>
                    <button className={styles.export} onClick={handleExportClick} disabled={isExporting}>
                        <span>
                            <img src={ExportButton} alt="" />
                        </span>
                        <span className={styles.exportButton}>{isExporting ? "Exporting..." : "Export"}</span>
                    </button>
                    <hr className={styles.separator} />
                    <button className={styles.filter} onClick={handleFilterOpen}>
                        <img src={filtersApplied ? ActiveFilter : FilterIcon} alt="filter" width={16} height={16} />
                        <span>Filters</span>
                    </button>
                </div>
            </div>
            <div className={styles.filterContainer}>
                <div className={styles.col1}>Personalize:</div>
                <div className={styles.col2}>
                    <SelectDropdown
                        options={questions}
                        labelField="name"
                        valueField="id"
                        placeholder="Select question"
                        onChange={handleSelectChange}
                        multi={false}
                        width="90%"
                        dropdownGap={0}
                        values={selectedQuestion ? [selectedQuestion] : []}
                    />
                </div>
                <div className={styles.col3}>
                    <SentimentFilter options={SENTIMENT_FILTER_OPTIONS} onSelectionChange={handleSelectionChange} selected={activeTags} />
                </div>
                <div className={styles.col4}>
                    <SelectDropdown
                        width="80%"
                        options={languageOptions}
                        labelField="label"
                        valueField="value"
                        placeholder="Translate"
                        onChange={handleChange}
                        multi={false}
                        dropdownGap={0}
                        values={selectedLanguage}
                    />
                </div>
            </div>

            <div className={styles.commentsWrapper}>
                {loading && (
                    <div className={styles.loaderContainer}>
                        <Loader size={50} />
                    </div>
                )}

                {error && <MessageCard title={ERROR_MSG.title} subtitle={ERROR_MSG.subtitle} text={ERROR_MSG.text} />}

                {!loading && !error && comments.length === 0 && <MessageCard title={NO_DATA_MSG.title} subtitle={NO_DATA_MSG.subtitle} text={NO_DATA_MSG.text} />}

                {!loading && !error && comments.length > 0 && (
                    <>
                        {selectedQuestion && (
                            <QuestionCard tag={1} question={selectedQuestion.name} contentHeight="fit-content">
                                <div className={styles.container}>
                                    {comments.map((comment) => (
                                        <div className={styles.gridKnot} key={comment.id}>
                                            <CommentCard sentiment={comment.sentiment_name} theme={comment.topic_name} comment={comment.answer} translationStatus={comment.translation_status} />
                                        </div>
                                    ))}
                                </div>
                            </QuestionCard>
                        )}
                    </>
                )}
                <div className={styles.infoContainer}>
                    <div className={styles.legendsCol}>
                        <SentimentLegend text={COMMENTS_LEGEND_TEXT} />
                    </div>
                    <div className={styles.paginationCol}>
                        {commentsCount != null && commentsCount > COMMENT_PAGE_LIMIT && (
                            <Pagination
                                sx={{
                                    "& .MuiPaginationItem-root.Mui-selected": {
                                        background: "#E6F2F9",
                                    },
                                }}
                                count={Math.ceil(commentsCount / COMMENT_PAGE_LIMIT)}
                                page={currentPage}
                                onChange={handlePageChange}
                                siblingCount={0}
                                showFirstButton
                                showLastButton
                            />
                        )}
                    </div>
                </div>
            </div>
            {modalOpen && (
                <TranslationDialog
                    open={modalOpen}
                    onClose={handleModalClose}
                    handleTranslate={handleTranslate}
                    handleCancel={handleTranslateClose}
                    language={selectedLanguage[0].label}
                    text={TRANSLATION_TEXT_COMMENTS}
                />
            )}
            {exportError && (
                <Snackbar open={exportError} autoHideDuration={5000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                    <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
                        {EXPORT_ERROR}
                    </Alert>
                </Snackbar>
            )}
            <ToastNotification open={displaySnackbar} onClose={() => dispatch(hideSnackbar())} message={SUMMARY_READY.text} title={SUMMARY_READY.title} />
            <Filter open={isOpen} handleFilterClose={handleFilterClose} />
            {unauthorized && <CustomizedDialog open={unauthorized} handleClick={handleClose} />}
        </div>
    );
};
export default Comments;
