import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LanguageState {
    confirmedLanguage: { value: string | number; label: string } | null;
}

const initialState: LanguageState = {
    confirmedLanguage: { value: "en", label: "English" }, // Default language is English
};

const selectedLanguageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<{ value: string | number; label: string }>) => {
            state.confirmedLanguage = action.payload;
        },
        resetLanguage: (state) => {
            state.confirmedLanguage = { value: "en", label: "English" };
        },
    },
});

export const { setLanguage, resetLanguage } = selectedLanguageSlice.actions;

export default selectedLanguageSlice.reducer;
