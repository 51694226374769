import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SentimentFiltersState {
    sentimentFilters: string[];
}

const initialState: SentimentFiltersState = {
    sentimentFilters: ["overall_summary"],
};

const sentimentFiltersSlice = createSlice({
    name: "sentimentFilters",
    initialState,
    reducers: {
        setSentimentFilters: (state, action: PayloadAction<string[]>) => {
            state.sentimentFilters = action.payload;
        },
        resetSentimentFilters: (state) => {
            state.sentimentFilters = initialState.sentimentFilters;
        },
    },
});

export const { setSentimentFilters, resetSentimentFilters } = sentimentFiltersSlice.actions;

export default sentimentFiltersSlice.reducer;
