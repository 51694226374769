import React from "react";

import styles from "./styles.module.css";

const CircularSpinner: React.FC = () => {
    return (
        <div className={styles.CircularSpinner}>
            {Array.from({ length: 12 }).map((_, index) => (
                <div key={index} className={styles.spinnerBar} />
            ))}
        </div>
    );
};

export default CircularSpinner;
