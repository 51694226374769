import { DialogContent, Box } from "@mui/material";

import * as React from "react";

import Close from "../../assets/close_icon.svg";
import Warning from "../../assets/warning-icon.svg";

import { CustomDialogActions, CustomCancelButton, CustomTranslateButton, ConfirmationDialog } from "../../styled/mui-styled";

import styles from "./styles.module.css";

interface BootstrapDialogProps {
    open: boolean;
    onClose: () => void;
    handleCancel: () => void;
    handleTranslate: () => void;
    language: string;
    text: {
        title: string;
        message: string;
        confirmation: string;
    };
}

const TranslationDialog: React.FC<BootstrapDialogProps> = ({ open, onClose, handleTranslate, handleCancel, language, text }) => {
    return (
        <ConfirmationDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
            <button className={styles.close} onClick={onClose}>
                <img src={Close} alt="Close" width={16} height={16} />
            </button>
            <DialogContent>
                <Box className={styles.content}>
                    <Box>
                        <img src={Warning} alt="warning" loading="lazy" />
                    </Box>
                    <Box className={styles.text}>{text.title}</Box>
                    <Box className={styles.subText}>
                        {text.message} <span className={styles.languageText}>&ldquo;{language}&rdquo;</span>.
                    </Box>
                    <Box className={styles.subText}>{text.confirmation}</Box>
                </Box>
            </DialogContent>

            <CustomDialogActions>
                <CustomCancelButton variant="contained" onClick={handleCancel}>
                    Cancel
                </CustomCancelButton>
                <CustomTranslateButton variant="contained" onClick={handleTranslate}>
                    Translate
                </CustomTranslateButton>
            </CustomDialogActions>
        </ConfirmationDialog>
    );
};

export default TranslationDialog;
