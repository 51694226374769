import { DialogContent, Typography } from "@mui/material";

import React from "react";

import Close from "../../assets/close_icon.svg";
import CopiedIcon from "../../assets/copied_icon.svg";
import CopyIcon from "../../assets/copy.svg";
import { StyledTooltip, StyledDialog } from "../../styled/mui-styled";

import styles from "./styles.module.css";

interface BootstrapDialogProps {
    open: boolean;
    onClose: () => void;
    backgroundColor: string;
    tag: string;
    theme?: string;
    comment: string;
    copied: boolean;
    handleCopyToClipboard: () => void;
}

const BootstrapDialog: React.FC<BootstrapDialogProps> = ({ open, onClose, backgroundColor, tag, theme, comment, copied, handleCopyToClipboard }) => {
    return (
        <StyledDialog open={open} onClose={onClose}>
            <button className={styles.close} onClick={onClose}>
                <img src={Close} alt="" width={20} height={20} />
            </button>
            <DialogContent>
                <div className={styles.cardTags}>
                    <div className={styles.cardCol1} style={{ width: "5%" }}>
                        <div className={styles.dot} style={{ backgroundColor }}></div>
                    </div>
                    <div className={styles.cardCol2} style={{ width: "90%" }}>
                        <div className={styles.theme}>
                            <div>{tag}</div>
                            <div>{theme}</div>
                        </div>
                    </div>
                    <div className={styles.cardCol3} style={{ width: "5%", justifyContent: "flex-start" }}>
                        <StyledTooltip title={copied ? "Copied!" : "Copy"}>
                            <button className={styles.button} onClick={handleCopyToClipboard}>
                                <img loading="lazy" src={copied ? CopiedIcon : CopyIcon} alt="copy" style={{ cursor: "pointer" }} />
                            </button>
                        </StyledTooltip>
                    </div>
                </div>
                <Typography>
                    <div className={styles.text}>{comment}</div>
                </Typography>
            </DialogContent>
        </StyledDialog>
    );
};

export default BootstrapDialog;
