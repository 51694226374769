/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box } from "@mui/material";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useEffect } from "react";

import CloseIcon from "../../assets/close.svg";
import DeleteIcon from "../../assets/delete.svg";
import EditIcon from "../../assets/edit.svg";
import ArrowRight from "../../assets/filter_arrow.svg";
import ArrowLeft from "../../assets/filter_left_arrow.svg";

import { PRESET_EDIT, SAVE_PRESET, BADGE_INPUT_PLACEHOLDER, RESPONDENTS, TOTAL_COMMENTS, THRESHOLD_REPORTING, DELETE_PRESET, THRESHOLD_ERROR } from "../../constants/constants";
import { extendedApiWrapper, FilterPresetRequest, UserPresetFilter, ValidateFiltersRequest } from "../../services/api-handlers";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { resetPreset, setSelectedPreset } from "../../store/preset-slice";
import { resetSelectedFilters, setFilters } from "../../store/selected-filter-slice";
import { deleteUserFilterPreset, saveUserFilterPreset } from "../../store/user-filter-preset";
import { CustomDialog, ResetButton, ApplyButton, PresetButton, DisabledButton, DisabledSaveButton } from "../../styled/mui-styled";
import { getFilterById } from "../../utils/utility-function";

import DemographicFilter from "./demographic-filter";
import InfoBadge from "./info-badge";
import PresetFilterDetails from "./peset-filter-details";
import PresetFilter from "./preset-filter";
import styles from "./styles.module.css";

interface DialogProps {
    open: boolean;
    handleFilterClose: () => void;
    onApplyFilter?: (filters: { selected: { [key: string]: string[] } }) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const Filter: React.FC<DialogProps> = ({ open, handleFilterClose, onApplyFilter }) => {
    const dispatch = useAppDispatch();
    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const surveyDetails = useAppSelector((state) => state.surveyDetails);
    const { fullPresetData, presets } = useAppSelector((state) => state.userFilterPresets);
    const presetId = useAppSelector((state) => state.preset.selectedPresetId);
    const savedFilters = useAppSelector((state) => state.selectedFilters.selectedFilters);

    const [showDemographicFilter, setShowDemographicFilter] = useState(false);
    const [showPresetFilter, setShowPresetFilter] = useState(false); // Track if preset filter is active
    const [showPresetDetails, setShowPresetDetails] = useState(false);
    const [editPresetOpen, setEditPresetOpen] = useState(false);
    const [selectedPresetId, setSelectedPresetId] = useState<string | null>(presetId || null);
    const [selectedPresetName, setSelectedPresetName] = useState<string | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>(savedFilters || {});
    const [detailedFilters, setDetailedFilters] = useState<Record<string, { options: { column_value: string; display_name: string; is_applied: boolean | null }[]; display_name: string }>>({});
    const [showSaveBadge, setShowSaveBadge] = useState<boolean>(false);
    const [isSaveButtonClicked, setIsSaveButtonClicked] = useState<boolean>(false);
    const [presetName, setPresetName] = useState<string>("");
    const [presetFilterDetailsById, setPresetFilterDetailsById] = useState<UserPresetFilter | null>(null);
    const [showDeleteBadge, setShowDeleteBadge] = useState<boolean>(false);
    const [showThresholdBadge, setShowThresholdBadge] = useState<boolean>(false);
    const [resetFilters, setResetFilters] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (Object.keys(selectedFilters).length > 0 && isSaveButtonClicked) {
            setShowSaveBadge(true);
        } else {
            setShowSaveBadge(false);
        }
    }, [isSaveButtonClicked, selectedFilters]);

    const handleShowDemographicFilter = () => {
        setShowDemographicFilter(true);
        setShowPresetFilter(false);
    };

    const handleShowPresetFilter = () => {
        setShowPresetFilter(true);
        setShowDemographicFilter(false);
    };

    // Handler to hide the demographic filter and show the right arrow
    const handleHideFilter = () => {
        setShowDemographicFilter(false);
        setShowPresetFilter(false);
        setSelectedFilters({});
        setShowSaveBadge(false);
    };

    const handleFiltersChange = (
        selected: { [key: string]: string[] },
        details: Record<string, { options: { column_value: string; display_name: string; is_applied: boolean | null }[]; display_name: string }>,
    ) => {
        setSelectedFilters(selected);
        setDetailedFilters(details);
        if (Object.keys(selected).length === 0) {
            setIsSaveButtonClicked(false);
        }
    };

    const handlePresetSelect = (presetId: string) => {
        const preset = fullPresetData.find((preset) => preset.id === presetId);
        if (preset) {
            const filters: { [key: string]: string[] } = {};

            // Iterate over the keys in demographic_filters
            for (const key in preset.demographic_filters) {
                if (Object.prototype.hasOwnProperty.call(preset.demographic_filters, key)) {
                    const filter = preset.demographic_filters[key];

                    // Extract the values for the filter
                    const appliedValues = filter.options
                        .filter((option) => option.is_applied) // Keep only applied options
                        .map((option) => option.column_value); // Get the column values

                    // Store only if there are applied values
                    if (appliedValues.length > 0) {
                        filters[key] = appliedValues; // Assign only if values are present
                    }
                }
            }
            setSelectedFilters(filters);
            setSelectedPresetId(presetId);
            setSelectedPresetName(presetName);
        }
    };

    const goToPresetDetails = (presetId: string, presetName: string) => {
        setSelectedPresetName(presetName);
        setSelectedPresetId(presetId);
        setShowPresetDetails(true);
        const filterObject = getFilterById(presetId, fullPresetData);
        setPresetFilterDetailsById(filterObject ?? null);
    };

    const handleEditPresetBadge = () => {
        setEditPresetOpen(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPresetName(e.target.value);
    };

    const onCheckboxClick = (key: string, optionKey: string, checked: boolean) => {
        setDetailedFilters((prev) => {
            const updatedOptions =
                prev[key]?.options?.map((option) => {
                    if (option.column_value === optionKey) {
                        return { ...option, is_applied: checked };
                    }
                    return option;
                }) || [];

            return {
                ...prev,
                [key]: {
                    ...prev[key],
                    options: updatedOptions,
                },
            };
        });
    };

    const handleSavePreset = () => {
        setShowSaveBadge(true);
        setIsSaveButtonClicked(true);
    };

    const handleEditPresetRemove = () => {
        setEditPresetOpen(false);
    };

    const handleSavePresetRemove = () => {
        setShowSaveBadge(false);
        setIsSaveButtonClicked(false);
    };

    const validateFilters = async () => {
        if (!selectedSurveyId) return;

        const requestBody: ValidateFiltersRequest = {
            filters: selectedFilters,
        };

        try {
            const response = await extendedApiWrapper.validateFilters({
                survey_id: selectedSurveyId,
                filters: requestBody,
            });
            if (response.data && response.data.detail === "filters are valid") {
                setShowThresholdBadge(false);
                return true;
            } else {
                setShowThresholdBadge(true);
                return false;
            }
        } catch (error) {
            console.error("Error validating filters:", error);
            return false;
        }
    };

    const onSaveApplyClick = async () => {
        if (!selectedSurveyId) return;
        dispatch(resetPreset());
        const name = editMode ? selectedPresetName : presetName;
        if (!name) return;

        const requestBody: FilterPresetRequest = {
            name: name,
            demographic_filters: detailedFilters,
        };
        if (editMode && selectedPresetId) {
            requestBody.id = selectedPresetId;
        }

        try {
            const response = await extendedApiWrapper.updateFilterPreset(selectedSurveyId, requestBody);
            if (response) {
                const updatedPreset = response.data as FilterPresetRequest;
                dispatch(saveUserFilterPreset({ surveyId: selectedSurveyId, preset: updatedPreset }));

                // Validate filters after updating the preset
                const areFiltersValid = await validateFilters();
                if (areFiltersValid) {
                    // Dispatch setFilters action
                    dispatch(setFilters({ selected: selectedFilters, details: detailedFilters }));
                    if (selectedPresetId || updatedPreset.id) {
                        dispatch(setSelectedPreset(selectedPresetId ?? (updatedPreset.id as string)));
                    }

                    if (onApplyFilter) {
                        onApplyFilter({ selected: selectedFilters });
                    }
                } else {
                    console.warn("Filters are not valid, cannot apply filters.");
                }

                handleSavePresetRemove();
                setShowDemographicFilter(false);
                setShowPresetFilter(true);
            } else {
                console.error("Failed to update preset:", response);
            }
        } catch (error) {
            console.error("Error updating preset:", error);
        }
    };

    const handleDeleteBadgeRemove = () => {
        setShowDeleteBadge(false);
    };

    const handleDeletePreset = async () => {
        if (selectedSurveyId && selectedPresetId) {
            const resultAction = await dispatch(deleteUserFilterPreset({ surveyId: selectedSurveyId, presetId: selectedPresetId }));

            if (deleteUserFilterPreset.fulfilled.match(resultAction)) {
                setSelectedPresetId(null);
                setShowDeleteBadge(false);
                setShowPresetFilter(true);
                setShowPresetDetails(false);
                setSelectedPresetName("");
                setEditPresetOpen(false);
            }
        }
    };

    const handlePresetFilterChange = (detailedFilters: Record<string, any>, formattedFilters: { [key: string]: string[] }) => {
        setDetailedFilters(detailedFilters);
        setSelectedFilters(formattedFilters);
    };

    const handleApplyFilter = async () => {
        if (editMode) {
            await onSaveApplyClick(); // Save and apply in edit mode
        } else {
            dispatch(resetPreset());
            const areFiltersValid = await validateFilters(); // Validate filters

            if (areFiltersValid) {
                // Dispatch action to save selected filters
                dispatch(setFilters({ selected: selectedFilters, details: detailedFilters }));
                if (selectedPresetId) {
                    dispatch(setSelectedPreset(selectedPresetId));
                }
                // Apply filters if valid
                if (onApplyFilter) {
                    onApplyFilter({ selected: selectedFilters });
                }
            }
        }
    };
    const handleReset = () => {
        if (selectedPresetId) {
            dispatch(resetPreset());
        }
        dispatch(resetSelectedFilters());
        setResetFilters(true);
        handleFilterClose();
    };

    return (
        <CustomDialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={fullScreen}
            PaperProps={{
                style: {
                    height: "100vh",
                    maxHeight: "100vh",
                    width: "28.5rem",
                    position: "fixed",
                    top: 0,
                    right: 0,
                    margin: 0,
                },
            }}
            onClose={handleFilterClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <Box sx={{ height: "100vh" }}>
                <Box className={styles.headerContainer} style={{ padding: `20px 40px ${showDemographicFilter || showPresetFilter ? "5px" : "40px"} 40px` }}>
                    <Box className={styles.header}>Filters</Box>
                    <Box className={styles.close}>
                        <img src={CloseIcon} alt="close" height={16} width={16} onClick={handleFilterClose} />
                    </Box>
                </Box>

                {/* Show filter summary only if demographic or preset filter is shown */}
                {(showDemographicFilter || showPresetFilter) && (
                    <Box className={styles.threshold}>
                        <Box>
                            {RESPONDENTS} {surveyDetails.data?.total_respondents}
                        </Box>
                        <Box>
                            {TOTAL_COMMENTS} {surveyDetails.data?.total_comments}
                        </Box>
                        <Box>
                            {THRESHOLD_REPORTING} {surveyDetails.data?.threshold}
                        </Box>
                    </Box>
                )}

                <Box
                    className={`${styles.container} ${showDemographicFilter || (showPresetFilter && !selectedPresetName) ? styles.activeContainer : ""} ${selectedPresetName ? styles.presetSelectedContainer : ""}`}
                >
                    {!showDemographicFilter && !showPresetFilter && !selectedPresetName ? (
                        <>
                            <Box className={styles.demo}>Demographics</Box>
                            <Box className={styles.right}>
                                <img src={ArrowRight} alt="" height={16} width={16} onClick={handleShowDemographicFilter} />
                            </Box>
                        </>
                    ) : selectedPresetName ? (
                        // If a preset is selected, show the preset name with the left arrow
                        <>
                            <Box className={styles.left} style={{ width: "9%" }}>
                                <img
                                    src={ArrowLeft}
                                    alt=""
                                    height={16}
                                    width={16}
                                    onClick={() => {
                                        setShowPresetDetails(false);
                                        setSelectedPresetId(null);
                                        setSelectedPresetName(null);
                                        setEditMode(false);
                                    }}
                                />
                            </Box>
                            <Box className={styles.text} style={{ width: "71%" }}>
                                {selectedPresetName}
                            </Box>
                            <Box className={styles.presetIconContainer}>
                                <Box>
                                    <img
                                        src={EditIcon}
                                        alt=""
                                        onClick={() => {
                                            setEditMode(true);
                                            setEditPresetOpen(false);
                                        }}
                                    />
                                </Box>
                                <hr />
                                <Box>
                                    <img src={DeleteIcon} alt="" onClick={() => setShowDeleteBadge(true)} />
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box className={styles.left}>
                                <img src={ArrowLeft} alt={showPresetFilter ? "Show demographic filter" : "Hide filter"} height={16} width={16} onClick={handleHideFilter} />
                            </Box>
                            <Box className={styles.text}>{showPresetFilter ? "Preset" : "Demographics"}</Box>
                        </>
                    )}
                </Box>

                {/* Render only the demographic filter or preset filter based on the active filter */}
                {showDemographicFilter && !showPresetDetails && (
                    <Box sx={{ height: "70%", overflow: "auto", padding: "0 40px" }}>
                        <DemographicFilter filters={surveyDetails.data?.demographic_filters ?? {}} onFiltersChange={handleFiltersChange} resetFilters={resetFilters} />
                    </Box>
                )}
                {showPresetFilter && !showPresetDetails && (
                    <Box sx={{ height: "70%", overflow: "auto", padding: "0 40px" }}>
                        <PresetFilter presets={presets} onPresetSelect={handlePresetSelect} onIconClick={goToPresetDetails} />
                    </Box>
                )}
                {showPresetDetails && presetFilterDetailsById?.demographic_filters && (
                    <Box sx={{ height: "70%", overflow: "auto", padding: "0 40px" }}>
                        <PresetFilterDetails
                            user_presets={presetFilterDetailsById?.demographic_filters}
                            onCheckboxClick={onCheckboxClick}
                            editMode={editMode}
                            onDisabledCheckboxClick={handleEditPresetBadge}
                            onFiltersChange={handlePresetFilterChange}
                        />
                    </Box>
                )}

                <Box className={styles.bottom}>
                    <Box className={styles.preset} style={{ visibility: showPresetFilter ? "hidden" : "visible" }}>
                        {/* Use visibility to hide but keep space */}
                        {showSaveBadge ? (
                            <DisabledSaveButton>SAVE PRESET</DisabledSaveButton>
                        ) : showDemographicFilter && Object.keys(selectedFilters).length > 0 ? (
                            <PresetButton onClick={handleSavePreset}>Save Preset</PresetButton>
                        ) : (
                            presets.length > 0 && (
                                <PresetButton onClick={handleShowPresetFilter} disabled={showPresetFilter}>
                                    Preset Filters
                                </PresetButton>
                            )
                        )}
                    </Box>
                    <Box className={styles.buttonGroup}>
                        {Object.keys(selectedFilters).length > 0 || selectedPresetId || showPresetDetails ? (
                            <>
                                <ResetButton variant="contained" editMode={editMode} onClick={handleReset}>
                                    Reset filter
                                </ResetButton>
                                <ApplyButton editMode={editMode} onClick={handleApplyFilter}>
                                    {editMode ? "Save & Apply Filter" : "Apply Filter"}
                                </ApplyButton>
                            </>
                        ) : (
                            <>
                                <DisabledButton>Reset filter</DisabledButton>
                                <DisabledButton>Apply filter</DisabledButton>
                            </>
                        )}
                    </Box>
                </Box>
                {editPresetOpen && <InfoBadge preset={PRESET_EDIT} onRemoveClick={handleEditPresetRemove} />}
                {showSaveBadge && (
                    <InfoBadge
                        preset={SAVE_PRESET}
                        showButtons
                        showTextField
                        textFieldPlaceholder={BADGE_INPUT_PLACEHOLDER}
                        presetName={presetName}
                        onInputChange={handleInputChange}
                        onRemoveClick={handleSavePresetRemove}
                        onSaveApplyClick={onSaveApplyClick}
                    />
                )}
                {showDeleteBadge && <InfoBadge preset={DELETE_PRESET} showButtons presetName={presetName} onRemoveClick={handleDeleteBadgeRemove} isDeleteVisible onDeleteClick={handleDeletePreset} />}
                {showThresholdBadge && <InfoBadge preset={THRESHOLD_ERROR} onRemoveClick={() => setShowThresholdBadge(false)} />}
            </Box>
        </CustomDialog>
    );
};

export default Filter;
