import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useParams } from "react-router-dom";

import PenIcon from "../../assets/pen_icon.svg";
import WhiteCloud from "../../assets/white_cloud.svg";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { fetchLanguages } from "../../store/language-slice";
import { resetPreset } from "../../store/preset-slice";
import { resetSelectedFilters } from "../../store/selected-filter-slice";
import { resetLanguage } from "../../store/selected-language-slice";
import { resetSentimentFilters } from "../../store/sentiment-filter-slice";
import { hideSnackbar } from "../../store/snackbar-slice";
import { fetchSurveyDetails } from "../../store/survey-details-slice";
import { setSelectedSurveyId } from "../../store/survey-slice";
import { fetchUserFilterPresets } from "../../store/user-filter-preset";
import { FeedbackButton, WordCloudButton } from "../../styled/mui-styled";
import { getLastPathSegment } from "../../utils/utility-function";
import Comments from "../comments/comments";
import FeedbackForm from "../feedback/feedback";
import NavBar from "../navbar/navbar";
import Overview from "../overview/overview";

import Questions from "../questions/questions";
import TextSummarisation from "../text-summarisation/text-summarisation";
import WorkplaceThemes from "../themes/workplace-themes";
import AlertDialogSlide from "../word-cloud/word-cloud";

import styles from "./styles.module.css";

const Survey: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);

    const { surveyId } = useParams();

    useEffect(() => {
        if (surveyId && !selectedSurveyId) {
            dispatch(setSelectedSurveyId(surveyId));
        }
        dispatch(fetchLanguages());
        return () => {
            dispatch(setSelectedSurveyId(null));
            dispatch(resetSelectedFilters());
            dispatch(resetPreset());
            dispatch(hideSnackbar());
            dispatch(resetLanguage());
            dispatch(resetSentimentFilters());
        };
    }, []);

    useEffect(() => {
        if (selectedSurveyId) {
            dispatch(fetchUserFilterPresets(selectedSurveyId));
            dispatch(fetchSurveyDetails(selectedSurveyId));
        }
    }, [selectedSurveyId]);

    const location = useLocation();
    const lastSegment = getLastPathSegment(location.pathname);
    const shouldHideButtons = ["questions", "overview", "comments"].includes(lastSegment);

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloudOpen = () => {
        setIsOpen(true);
    };
    const handleCloudClose = () => {
        setIsOpen(false);
    };
    return (
        <div className={styles.surveyContainer}>
            <NavBar />
            <Box className={styles.mainContent}>
                <Box className={styles.content}>
                    <Box className={styles.buttonContainer}>
                        <Box>
                            {!shouldHideButtons && (
                                <WordCloudButton variant="contained" onClick={handleCloudOpen}>
                                    <span className={styles.icon}>
                                        <img src={WhiteCloud} alt="" />
                                    </span>
                                    WordCloud
                                </WordCloudButton>
                            )}
                        </Box>
                        <Box>
                            <FeedbackButton variant="contained" onClick={handleClick}>
                                <span className={styles.icon}>
                                    <img src={PenIcon} alt="" />
                                </span>
                                Feedback
                            </FeedbackButton>
                        </Box>
                    </Box>
                </Box>
                <Box className={styles.routeContainer}>
                    <Routes>
                        <Route path="*" element={<Navigate to="overview" replace />} />
                        <Route path="overview" element={<Overview />} />
                        <Route path="/workplace-themes" element={<WorkplaceThemes />} />
                        <Route path="/text-summarisation" element={<TextSummarisation />} />
                        <Route path="/comments" element={<Comments />} />
                        <Route path="/questions" element={<Questions />} />
                    </Routes>
                </Box>
            </Box>

            <FeedbackForm open={open} handleClose={handleClose} />
            <AlertDialogSlide open={isOpen} handleCloudClose={handleCloudClose} />
        </div>
    );
};

export default Survey;
