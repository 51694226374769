import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { extendedApiWrapper, LanguageMap } from "../services/api-handlers";

export const fetchLanguages = createAsyncThunk<LanguageMap, void, { rejectValue: string }>("languages/fetchLanguages", async (_, { rejectWithValue }) => {
    try {
        const response = await extendedApiWrapper.getLanguages();

        if (!response.data) {
            return rejectWithValue("No languages found");
        }
        return response.data;
    } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
        return rejectWithValue(errorMessage);
    }
});

interface LanguagesState {
    data: LanguageMap | null;
    loading: boolean;
    error: string | null;
}

const initialState: LanguagesState = {
    data: null,
    loading: false,
    error: null,
};

const languagesSlice = createSlice({
    name: "languages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLanguages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLanguages.fulfilled, (state, action: PayloadAction<LanguageMap>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchLanguages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "An error occurred while fetching languages"; // Handle any errors
            });
    },
});

export default languagesSlice.reducer;
