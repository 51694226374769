import { FormGroup } from "@mui/material";

import React from "react";

import { SentimentCheckbox, CustomFormControlLabel } from "../../styled/mui-styled";

interface CheckboxGroupProps {
    options: { label: string; value: string }[];
    onSelectionChange: (selectedValues: string[]) => void;
    selected: string[];
}

const SentimentFilter: React.FC<CheckboxGroupProps> = ({ options, onSelectionChange, selected }) => {
    const handleChange = (value: string) => {
        const newSelected = selected.includes(value) ? selected.filter((item) => item !== value) : [...selected, value];
        onSelectionChange(newSelected);
    };

    return (
        <FormGroup row>
            {options.map((option) => (
                <CustomFormControlLabel key={option.value} control={<SentimentCheckbox checked={selected.includes(option.value)} onChange={() => handleChange(option.value)} />} label={option.label} />
            ))}
        </FormGroup>
    );
};

export default SentimentFilter;
