import React from "react";

import styles from "./styles.module.css";

interface SentimentLegendProps {
    text: string;
}

const SentimentLegend: React.FC<SentimentLegendProps> = ({ text }) => {
    return (
        <div className={styles.container}>
            <span className={styles.label}>{text}</span>
            <ul className={styles.legend}>
                <li className={`${styles.legendItem} ${styles.negative}`}>
                    <span className={styles.colorBox}></span>
                    Negative
                </li>
                <li className={`${styles.legendItem} ${styles.neutral}`}>
                    <span className={styles.colorBox}></span>
                    Neutral
                </li>
                <li className={`${styles.legendItem} ${styles.positive}`}>
                    <span className={styles.colorBox}></span>
                    Positive
                </li>
            </ul>
        </div>
    );
};

export default SentimentLegend;
