import copy from "copy-to-clipboard";

import React, { useState } from "react";

import CopiedIcon from "../../assets/copied_icon.svg";
import CopyIcon from "../../assets/copy.svg";

import { COMMENT_TRUNCATE_LENGTH } from "../../constants/constants";
import { SentimentOptions } from "../../services/api-handlers";
import { StyledTooltip } from "../../styled/mui-styled";

import BootstrapDialog from "./comment-dialog";
import Spinner from "./spinner";

import styles from "./styles.module.css";

const colorMap: Record<string, string> = {
    Negative: "#FF7A9F",
    Neutral: "#E6E6E6",
    Positive: "#12A195",
};
interface CommentCardProps {
    sentiment: SentimentOptions | "neutral";
    tag?: string;
    theme?: string;
    comment: string;
    translationStatus?: string;
}

const CommentCard: React.FC<CommentCardProps> = ({ sentiment, tag = "Workplace Theme:", theme, comment, translationStatus }) => {
    const backgroundColor = colorMap[sentiment];
    const [showModal, setShowModal] = useState<boolean>(false);
    const [copied, setCopied] = useState(false);

    const showReadMore = comment ? comment.length > COMMENT_TRUNCATE_LENGTH : false;

    const handleCopied = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const handleCopyToClipboard = () => {
        if (comment) {
            copy(comment);
            handleCopied();
        }
    };

    const handleReadMore = () => {
        setCopied(false);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setCopied(false);
        setShowModal(false);
    };

    // Determine the text to display
    let displayedText;
    if (translationStatus === "processing") {
        displayedText = (
            <div className={styles.translatingMessage}>
                <Spinner />
                <span>Translating your data...</span>
            </div>
        );
    } else if (translationStatus === "failed") {
        displayedText = <div className={styles.failedMessage}>Failed to translate data</div>;
    } else if (showReadMore) {
        displayedText = (
            <>
                {comment.slice(0, COMMENT_TRUNCATE_LENGTH)}
                &nbsp;&nbsp;
                <button className={styles.readMoreButton} onClick={handleReadMore}>
                    Read More
                </button>
            </>
        );
    } else {
        displayedText = comment;
    }

    return (
        <div className={styles.cardContainer}>
            <div className={styles.cardTags}>
                <div className={styles.cardCol1}>
                    <div className={styles.dot} style={{ backgroundColor }}></div>
                </div>
                <div className={styles.cardCol2}>
                    <div className={styles.theme}>
                        <div>{tag}</div>
                        <div>{theme}</div>
                    </div>
                </div>
                <div className={styles.cardCol3}>
                    <StyledTooltip title={copied ? "Copied!" : "Copy"}>
                        <button className={styles.button} onClick={handleCopyToClipboard}>
                            <img loading="lazy" src={copied ? CopiedIcon : CopyIcon} alt="copy" style={{ cursor: "pointer" }} />
                        </button>
                    </StyledTooltip>
                </div>
            </div>
            <div className={styles.text}>{displayedText}</div>
            <BootstrapDialog
                open={showModal}
                onClose={handleCloseModal}
                backgroundColor={backgroundColor}
                tag={tag}
                theme={theme}
                comment={comment}
                copied={copied}
                handleCopyToClipboard={handleCopyToClipboard}
            />
        </div>
    );
};
export default CommentCard;
