import React from "react";

import { NavLink } from "react-router-dom";

import CommentsIcon from "../../assets/comments_icon.svg";
import OverviewIcon from "../../assets/overview_icon.svg";
import QuestionsIcon from "../../assets/questions_icon.svg";
import SummarisationIcon from "../../assets/summarisation.svg";
import ThemesIcon from "../../assets/themes_icon.svg";

import styles from "./styles.module.css";

const NavBar: React.FC = () => {
    return (
        <div className={styles.navContainer}>
            <NavLink to="overview" className={({ isActive }) => (isActive ? styles.activeNavItem : styles.navItem)}>
                <span className={styles.icon}>
                    <img src={OverviewIcon} alt="overview" />
                </span>
                Overview
            </NavLink>
            <NavLink to="text-summarisation" className={({ isActive }) => (isActive ? styles.activeNavItem : styles.navItem)}>
                <span className={styles.icon}>
                    <img src={SummarisationIcon} alt="summary" width={18} height={16} />
                </span>
                Text Summarisation
            </NavLink>
            <NavLink to="workplace-themes" className={({ isActive }) => (isActive ? styles.activeNavItem : styles.navItem)}>
                <span className={styles.icon}>
                    <img src={ThemesIcon} alt="theme" />
                </span>
                Workplace Themes
            </NavLink>
            <NavLink to="questions" className={({ isActive }) => (isActive ? styles.activeNavItem : styles.navItem)}>
                <span className={styles.icon}>
                    <img src={QuestionsIcon} alt="questions" />
                </span>
                Questions
            </NavLink>
            <NavLink to="comments" className={({ isActive }) => (isActive ? styles.activeNavItem : styles.navItem)}>
                <span className={styles.icon}>
                    <img src={CommentsIcon} alt="comments" />
                </span>
                Comments
            </NavLink>
        </div>
    );
};

export default NavBar;
