/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CircularProgress } from "@mui/material";

import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import ActiveFilter from "../../assets/active_filter.svg";
import FilterIcon from "../../assets/filter.svg";
import {
    SUMMARISATION_SLIDER_LABELS,
    TAG_TO_SUMMARY_TYPE,
    ERROR_MSG,
    POLL_INTERVAL,
    MAX_POLL_ATTEMPTS,
    NO_DATA_MSG,
    SENTIMENT_FILTER_OPTIONS,
    TRANSLATION_TEXT_SUMMARISATION,
} from "../../constants/constants";
import { extendedApiWrapper, QuestionListResponse, SummarizedTextResponse, SummarizedTextRequest } from "../../services/api-handlers";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setLanguage } from "../../store/selected-language-slice";
import { setSentimentFilters } from "../../store/sentiment-filter-slice";
import { showSnackbar } from "../../store/snackbar-slice";
import { mapSliderValueToLength, convertObjectToOptions } from "../../utils/utility-function";
import CustomizedDialog from "../dialog/timeout-dialog";
import TranslationDialog from "../dialog/translation-dialog";
import Filter from "../filter/filter";
import MessageCard from "../message-card/message-card";
import RangeSlider from "../range-slider/range-slider";
import SelectDropdown from "../select-dropdown/select-dropdown";
import SentimentFilter from "../sentiment-filter/sentiment-filter";

import QuestionCard from "./question-card";
import RowRadioButtonsGroup from "./radio-group";

import styles from "./styles.module.css";
import FilterSummaryLoader from "./summary-loader";

export type LanguageOption = { value: string | number; label: string };

const TextSummarisation = () => {
    const [sliderValue, setSliderValue] = useState<number>(0);
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionListResponse | null>(null);
    const [questions, setQuestions] = useState<QuestionListResponse[]>([]);
    const [selectedFormat, setSelectedFormat] = useState<string>("paragraph");
    const [summarizedText, setSummarizedText] = useState<SummarizedTextResponse | null>(null);
    const [summaryLoading, setSummaryLoading] = useState(false);
    const [summaryError, setSummaryError] = useState(false);
    const [unauthorized, setUnauthorized] = useState(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption[]>([{ value: "en", label: "English" }]);
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmedLanguage, setConfirmedLanguage] = useState<LanguageOption[]>([{ value: "en", label: "English" }]);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const pollingInterval = useRef<NodeJS.Timeout | null>(null);
    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const filters = useAppSelector((state) => state.selectedFilters.selectedFilters);
    const languageList = useAppSelector((state) => state.languages.data);
    const language = useAppSelector((state) => state.selectedLanguage.confirmedLanguage);
    const sentimentFilter = useAppSelector((state) => state.sentimentFilter.sentimentFilters);

    const [activeTags, setActiveTags] = useState<string[]>(sentimentFilter || ["overall_summary"]);

    const mapActiveTagsToSummaryTypes = (tags: string[]): string[] => {
        if (tags.length === 0) {
            return ["overall_summary"];
        }
        return tags.map((tag) => TAG_TO_SUMMARY_TYPE[tag]);
    };

    useEffect(() => {
        const fetchQuestions = async () => {
            if (!selectedSurveyId) return;
            try {
                const response = await extendedApiWrapper.getQuestionsList(selectedSurveyId);
                if (response.data && Array.isArray(response.data)) {
                    setQuestions(response.data);
                    setSelectedQuestion(response.data[0]);
                } else if (response.status === 401) {
                    setUnauthorized(true);
                }
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
        };

        fetchQuestions();
    }, [selectedSurveyId]);

    const fetchSummarizedText = async (appliedFilters: Record<string, string[]> | null = null) => {
        if (pollingInterval.current) {
            // Clear any ongoing polling before starting a new one
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
        }
        if (selectedSurveyId && selectedQuestion) {
            const isFilterApplied = !!appliedFilters || Object.keys(filters).length > 0;
            setFilterLoading(isFilterApplied || (confirmedLanguage.length > 0 && confirmedLanguage[0].value !== "en"));
            setSummaryLoading(true);
            setSummaryError(false);
            setSummarizedText(null);

            const requestData: SummarizedTextRequest = {
                survey_id: selectedSurveyId,
                question_id: selectedQuestion.id,
                language_code: String(language?.value) || "en",
                summary_type: mapActiveTagsToSummaryTypes(activeTags),
                content_type: selectedFormat,
                summary_length_type: mapSliderValueToLength(sliderValue),
                filters: appliedFilters ?? filters,
            };

            let response;

            try {
                response = await extendedApiWrapper.getSummarizedText(requestData);

                if (response.status === 202) {
                    let attempts = 0;

                    pollingInterval.current = setInterval(async () => {
                        if (attempts >= MAX_POLL_ATTEMPTS) {
                            clearInterval(pollingInterval.current!);
                            setSummaryLoading(false);
                            setFilterLoading(false);
                            setSummaryError(true);
                            setSummarizedText(null);
                            console.error("Polling timed out");
                            return;
                        }

                        try {
                            response = await extendedApiWrapper.getSummarizedText(requestData);
                            if (response.status === 200 && response.data) {
                                clearInterval(pollingInterval.current!);
                                dispatch(showSnackbar());
                                setSummarizedText(response.data);
                                setFiltersApplied(isFilterApplied);
                                setSummaryLoading(false);
                                setFilterLoading(false);
                            }
                        } catch (error) {
                            clearInterval(pollingInterval.current!);
                            setSummaryError(true);
                            console.error("Error during polling:", error);
                        }
                        attempts += 1;
                    }, POLL_INTERVAL);
                } else if (response.data) {
                    setSummarizedText(response.data);
                    setFiltersApplied(isFilterApplied);
                } else if (response.status === 401) {
                    setUnauthorized(true);
                    setSummaryError(true);
                }
            } catch (error) {
                console.error("Error fetching summarized text:", error);
                setSummaryError(true);
            } finally {
                if (response && response.status !== 202) {
                    setSummaryLoading(false);
                    setFilterLoading(false);
                }
            }
        }
    };

    const handleFilterClose = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchSummarizedText(filters);
            handleFilterClose();
        } else {
            fetchSummarizedText();
        }
    }, [selectedQuestion, sliderValue, selectedFormat, activeTags, filters, confirmedLanguage]);

    useEffect(() => {
        // If language is null, fallback to English
        const languageToSet = language ? [language] : [{ value: "en", label: "English" }];
        setConfirmedLanguage(languageToSet);
    }, [language]);

    useEffect(() => {
        setSelectedLanguage(confirmedLanguage); // Sync selectedLanguage with confirmedLanguage
    }, [confirmedLanguage]);

    useEffect(() => {
        if (sentimentFilter) {
            setActiveTags(sentimentFilter);
        }
    }, [sentimentFilter]);

    const handleSliderChange = (value: number) => {
        setSliderValue(value);
    };

    const handleSelectionChange = (selectedValues: string[]) => {
        const updatedTags = selectedValues.length === 0 ? ["overall_summary"] : selectedValues;

        // If "overall_summary" is selected, we need to remove it if there are other filters
        if (updatedTags.length > 1 && updatedTags.includes("overall_summary")) {
            updatedTags.splice(updatedTags.indexOf("overall_summary"), 1);
        }

        // Update state and dispatch action
        setActiveTags(updatedTags);
        dispatch(setSentimentFilters(updatedTags));
    };

    const handleSelectChange = (values: QuestionListResponse[]) => {
        setSelectedQuestion(values.length > 0 ? values[0] : null);
    };

    const handleFormatChange = (value: string) => {
        setSelectedFormat(value);
    };

    const selectedQuestionIndex = questions.findIndex((q) => q.id === selectedQuestion?.id);
    const handleClose = () => {
        setUnauthorized(false);
        navigate(0);
    };
    const handleFilterOpen = () => {
        setIsOpen(true);
    };

    const handleChange = (selected: LanguageOption[]) => {
        if (selected.length === 0) {
            return;
        }
        const selectedLang = selected[0];

        // If the selected language is the same as the confirmed language, do nothing
        if (selectedLang.value === confirmedLanguage[0]?.value) {
            return;
        }

        // If the selected language is English, update confirmed language directly and don't open the modal
        if (selectedLang.value === "en") {
            setConfirmedLanguage(selected);
            dispatch(setLanguage(selected[0]));
            return; // Don't open the modal if the selected language is English
        }

        // For non-English languages, open the modal and set the selected language temporarily
        setSelectedLanguage(selected);
        setModalOpen(true);
    };

    const languageOptions = useMemo(() => {
        return convertObjectToOptions(languageList || {});
    }, [languageList]);

    const handleModalClose = () => {
        setModalOpen(false);
    };
    const handleCancel = () => {
        // Revert language to previously confirmed language
        setSelectedLanguage(confirmedLanguage);

        // Close the modal
        setModalOpen(false);
    };
    const handleTranslateClose = () => {
        handleCancel();
    };
    const handleTranslate = () => {
        setConfirmedLanguage(selectedLanguage);
        dispatch(setLanguage(selectedLanguage[0]));
        handleModalClose();
    };

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.content}>Text Summarisation</div>
                <button className={styles.filter} onClick={handleFilterOpen}>
                    <img src={filtersApplied ? ActiveFilter : FilterIcon} alt="filter" width={16} height={16} />
                    <span>Filters</span>
                </button>
            </div>
            <div className={styles.filterContainer}>
                <div className={styles.col1}>Personalize:</div>
                <div className={styles.col2}>
                    <RowRadioButtonsGroup onChange={handleFormatChange} />
                </div>
                <div className={styles.col3}>
                    <RangeSlider min={0} max={100} defaultValue={0} labels={SUMMARISATION_SLIDER_LABELS} step={100} onChange={handleSliderChange} />
                </div>
                <div className={styles.col4}>
                    <SentimentFilter options={SENTIMENT_FILTER_OPTIONS} onSelectionChange={handleSelectionChange} selected={activeTags} />
                </div>

                <div className={styles.col5}>
                    <SelectDropdown
                        width="85%"
                        options={languageOptions}
                        labelField="label"
                        valueField="value"
                        placeholder="Translate"
                        onChange={handleChange}
                        multi={false}
                        dropdownGap={0}
                        values={selectedLanguage.length > 0 ? selectedLanguage : confirmedLanguage}
                    />
                </div>
            </div>
            <div className={styles.dropdownContainer}>
                <SelectDropdown
                    options={questions}
                    width="40%"
                    labelField="name"
                    valueField="id"
                    placeholder="Select question"
                    onChange={handleSelectChange}
                    multi={false}
                    dropdownGap={0}
                    values={selectedQuestion ? [selectedQuestion] : []}
                />
            </div>
            <div className={styles.questionContainer}>
                {filterLoading && <FilterSummaryLoader />}
                {summaryLoading && !filterLoading && (
                    <div className={styles.loader}>
                        <CircularProgress size={50} />
                    </div>
                )}
                {summaryError && <MessageCard title={ERROR_MSG.title} subtitle={ERROR_MSG.subtitle} text={ERROR_MSG.text} />}
                {!filterLoading && !summaryLoading && !summaryError && summarizedText?.data.length === 0 && (
                    <MessageCard title={NO_DATA_MSG.title} subtitle={NO_DATA_MSG.subtitle} text={NO_DATA_MSG.text} />
                )}
                {!filterLoading && !summaryLoading && !summaryError && summarizedText && summarizedText.data.length > 0 && selectedQuestion && (
                    <QuestionCard
                        tag={selectedQuestionIndex + 1}
                        question={selectedQuestion.name}
                        overall_summary={
                            summarizedText?.data[0]?.overall_summary && {
                                text: summarizedText.data[0].overall_summary.text,
                                keywords: summarizedText.data[0].overall_summary.keywords,
                            }
                        }
                        positive_summary={
                            summarizedText?.data[0]?.positive_summary && {
                                text: summarizedText.data[0].positive_summary.text,
                                keywords: summarizedText.data[0].positive_summary.keywords,
                            }
                        }
                        negative_summary={
                            summarizedText?.data[0]?.negative_summary && {
                                text: summarizedText.data[0]?.negative_summary.text,
                                keywords: summarizedText.data[0]?.negative_summary.keywords,
                            }
                        }
                        neutral_summary={
                            summarizedText?.data[0]?.neutral_summary && {
                                text: summarizedText.data[0].neutral_summary.text,
                                keywords: summarizedText.data[0].neutral_summary.keywords,
                            }
                        }
                    />
                )}
            </div>
            {modalOpen && (
                <TranslationDialog
                    open={modalOpen}
                    onClose={handleModalClose}
                    handleTranslate={handleTranslate}
                    handleCancel={handleTranslateClose}
                    language={selectedLanguage[0].label}
                    text={TRANSLATION_TEXT_SUMMARISATION}
                />
            )}
            <Filter open={isOpen} handleFilterClose={handleFilterClose} />
            {unauthorized && <CustomizedDialog open={unauthorized} handleClick={handleClose} />}
        </div>
    );
};

export default TextSummarisation;
