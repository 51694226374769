import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ActiveFilter from "../../assets/active_filter.svg";
import FilterIcon from "../../assets/filter.svg";
import SortIcon from "../../assets/sort-icon.svg";
import { ERROR_MSG, THEME_HEADERS, THEME_COLUMN_WIDTHS, NO_DATA_MSG, SUMMARY_READY, THEMES_LEGEND_TEXT } from "../../constants/constants";
import { ThemeResponse, extendedApiWrapper } from "../../services/api-handlers";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { hideSnackbar } from "../../store/snackbar-slice";
import CustomizedDialog from "../dialog/timeout-dialog";
import Filter from "../filter/filter";
import Legends from "../legends/legends";
import Loader from "../loader/auth-loader";
import MessageCard from "../message-card/message-card";
import LinearProgress from "../progress/linear-progress";
import ToastNotification from "../snackbar/snackbar";
import SentimentTable from "../table/table";

import styles from "./styles.module.css";

export const keyExtractor = (header: string) => {
    const map: Record<string, keyof ThemeResponse> = {
        "Automated Workplace Themes": "topic_name",
        "Sentiment Breakdown": "sentiment_breakdown",
        "Presence Percentage": "mention_percentage",
        "Number of Comments": "comment_count",
    };
    return map[header];
};

const WorkplaceThemes = () => {
    const [data, setData] = useState<ThemeResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [unauthorized, setUnauthorized] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const filters = useAppSelector((state) => state.selectedFilters.selectedFilters);
    const displaySnackbar = useAppSelector((state) => state.snackbar.open);

    const fetchWorkplaceThemes = async (appliedFilters: Record<string, string[]> | null = null) => {
        if (!selectedSurveyId) return; // Exit if no survey ID is selected
        setLoading(true);
        try {
            // Set the request body with either applied filters or global filters
            const requestBody = appliedFilters ? { filters: appliedFilters } : { filters };

            const response = await extendedApiWrapper.getWorkplaceThemes(selectedSurveyId, requestBody);

            if (response.data && Array.isArray(response.data)) {
                setData(response.data);
                setFiltersApplied(!!appliedFilters || Object.keys(filters).length > 0);
            } else if (response.status === 401) {
                setUnauthorized(true);
            } else {
                setError(true);
            }
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchWorkplaceThemes(filters);
        } else {
            fetchWorkplaceThemes();
        }
    }, [selectedSurveyId, filters]);

    const handleClose = () => {
        setUnauthorized(false);
        navigate(0);
    };

    const handleFilterOpen = () => {
        setIsOpen(true);
    };
    const handleFilterClose = () => {
        setIsOpen(false);
    };

    const onApplyFilter = (filters: { selected: Record<string, string[]> }) => {
        const appliedFilters = filters.selected;
        fetchWorkplaceThemes(appliedFilters);
        handleFilterClose();
    };

    if (loading) return <Loader size={50} />;

    if (error) {
        return <MessageCard title={ERROR_MSG.title} subtitle={ERROR_MSG.subtitle} text={ERROR_MSG.text} />;
    }

    if (data.length === 0) {
        return <MessageCard title={NO_DATA_MSG.title} subtitle={NO_DATA_MSG.subtitle} text={NO_DATA_MSG.text} />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.content}>Workplace Themes</div>
                <button className={styles.filter} onClick={handleFilterOpen}>
                    <img src={filtersApplied ? ActiveFilter : FilterIcon} alt="filter" width={16} height={16} />
                    <span>Filters</span>
                </button>
            </div>
            <SentimentTable
                headers={THEME_HEADERS}
                data={data}
                sortIcon={SortIcon}
                columnWidths={THEME_COLUMN_WIDTHS}
                keyExtractor={keyExtractor}
                sortableColumns={[0, 2, 3]}
                renderCell={(item, columnIndex) => {
                    switch (columnIndex) {
                        case 0:
                            return item.topic_name;
                        case 1:
                            return <LinearProgress sentimentBreakdown={item.sentiment_breakdown} />;
                        case 2:
                            return `${item.mention_percentage}%`;
                        case 3:
                            return item.comment_count;
                        default:
                            return null;
                    }
                }}
            />
            <div className={styles.legendsContainer}>
                <Legends text={THEMES_LEGEND_TEXT} />
            </div>
            <ToastNotification open={displaySnackbar} onClose={() => dispatch(hideSnackbar())} message={SUMMARY_READY.text} title={SUMMARY_READY.title} />
            <Filter open={isOpen} handleFilterClose={handleFilterClose} onApplyFilter={onApplyFilter} />
            {unauthorized && <CustomizedDialog open handleClick={handleClose} />}
        </div>
    );
};

export default WorkplaceThemes;
